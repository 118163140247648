<template>
  <div>
    <date-picker
      v-model="value1"
      type="week"
      :lang="lang"
      format="YYYY-MM-DD"
      :show-week-number="false"
      :disabled-date="disabledDatesSelector1"
      :disabled="disabledFirstPicker"
      :clearable="false"
      :editable="false"
    >
      <template slot="input">
        <template v-if="readOnly">
          <label class="opacity-6 mb-0">{{ $t('from') }}</label>
          <b-form-input
            type="text"
            readonly
            v-model="value1week"
            :disabled="disabledFirstPicker"
            :class="{ disabled: disabledFirstPicker }"
        /></template>
        <template v-else>
          <label class="opacity-6 mb-0">{{ $t('from') }}</label>
          <b-form-input type="text" v-model="value1week" readonly />
        </template>
      </template>
      <template slot="icon-calendar">
        <img
          :src="require(`@/assets/icons/calendar.svg`)"
          class="calendar-icon icon-style"
        />
      </template>
    </date-picker>
    <div class="mt-2"></div>
    <date-picker
      v-model="value2"
      type="week"
      :lang="lang"
      format="YYYY-MM-DD"
      :show-week-number="false"
      :disabled-date="disabledDatesSelector2"
      :disabled="disableSecondPicker"
      :clearable="false"
      :editable="false"
    >
      <template slot="input">
        <label class="opacity-6 mb-0">{{ $t('to') }}</label>
        <b-form-input
          type="text"
          readonly
          v-model="value2week"
          :disabled="disableSecondPicker"
          :class="{ disabled: disableSecondPicker }"
        />
      </template>
      <template slot="icon-calendar">
        <img
          :src="require(`@/assets/icons/calendar.svg`)"
          class="calendar-icon"
        />
      </template>
    </date-picker>
    <div class="d-flex justify-content-around pt-4" v-if="!readOnly">
      <b-button
        variant="secondary"
        class="rounded-pill"
        @click="clearDate"
        :disabled="disableButtonC"
        data-testid="clear-button"
      >
        {{ $t('clear') }}
      </b-button>
      <b-button
        variant="primary"
        class="rounded-pill"
        @click="handleRangeDateClick"
        :disabled="disableButtonA"
        data-testid="apply-button"
      >
        {{ $t('apply') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: { DatePicker },

  props: {
    range: Object,
    dateStart: String,
    dateEnd: String,
    readOnly: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      value1: '',
      value2: '',
      day: '',
      value1week: this.$t('select_date'),
      value2week: this.$t('select_date'),
      formattedDate: '',
      disableSecondPicker: true,
      disabledFirstPicker: false,
      disableButtonC: true,
      disableButtonA: true,
      currentDate: new Date(),
      isRelapseView: this.$route.name.includes('view-relapse'),
    };
  },

  watch: {
    readOnly: {
      handler(value) {
        if (value) {
          this.disabledFirstPicker = true;
          this.value1week = this.moment(this.dateStart).format('YYYY-MM-DD');
          this.value2week = this.moment(this.dateEnd).format('YYYY-MM-DD');
        }
      },
      immediate: true,
    },
    value1: function (value) {
      this.modifySecondValue();
      this.get_Start_End_Dates(value, 'getMonday');
      let mondayAnyWeek = this.getMondayOfAnyWeek(value);
      this.disableSecondPicker = false;
      if (value === '' || value === this.$t('select_date') || value === null) {
        this.disableButtonA = true;
        this.disableButtonC = true;
        this.disableSecondPicker = true;
      }
      if (
        this.moment(mondayAnyWeek).format('YYYY-MM-DD') ===
        this.getMondayOfCurrentWeek()
      ) {
        this.disableSecondPicker = true;
      }
    },
    value2: function (value) {
      if (value !== '') {
        this.get_Start_End_Dates(value, 'getSunday');
        if (value) {
          this.disableButtonA = false;
        }
      }
    },
    range: function () {
      if (
        this.range.start === 'Invalid date' ||
        this.range.end === 'Invalid date'
      ) {
        this.value1week = this.$t('select_date');
        this.value2week = this.$t('select_date');
        this.range.start = null;
        this.range.end = null;
      }
    },
  },
  methods: {
    get_Start_End_Dates(dat, weekDay) {
      let mondayOfWeek = this.getMondayOfAnyWeek(dat);
      if (weekDay === 'getMonday') {
        let monday = this.moment(mondayOfWeek).format('YYYY-MM-DD');
        this.range.start = monday;
        this.value1week = monday;
        this.disableButtonC = false;
        if (monday === this.getMondayOfCurrentWeek()) {
          let sunday = this.getSundayOfCurrentWeek();
          this.range.end = sunday;
          this.value2week = sunday;
          this.disableButtonA = false;
        } else {
          this.disableButtonC = false;
        }
      }
      if (weekDay === 'getSunday') {
        let sunday = this.moment(mondayOfWeek)
          .add(6, 'days')
          .format('YYYY-MM-DD');
        this.range.end = sunday;
        this.value2week = sunday;
      }
    },

    disabledDatesSelector1(date) {
      const today = new Date();
      let startDate = this.moment(this.dateStart).format();
      today.setHours(0, 0, 0, 0);
      const hyphensRemoved = startDate.replaceAll('-', '');
      const year = +hyphensRemoved.substring(0, 4);
      const month = +hyphensRemoved.substring(4, 6);
      const day = +hyphensRemoved.substring(6, 8);
      const formattedDate = new Date(year, month - 1, day);
      const first = today.getDate() - today.getDay() + 1;
      const monday = new Date(today.setDate(first));
      const nextMonday = this.moment(monday).add(7, 'days');
      this.formattedDate = formattedDate;

      return date < formattedDate || date >= nextMonday;
    },

    disabledDatesSelector2(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const first = today.getDate() - today.getDay() + 1;
      const upperDay = new Date(today.setDate(first));
      const selectedValue = new Date(this.value1);
      const firstDay = selectedValue.getDate() - selectedValue.getDay() + 1;
      const last = firstDay + 6;
      const sunday = new Date(selectedValue.setDate(last));

      return date < sunday || date >= upperDay;
    },
    getMondayOfAnyWeek(date) {
      let d = new Date(date);
      let day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1);

      return new Date(d.setDate(diff));
    },
    getMondayOfCurrentWeek() {
      const today = new Date();
      const first = today.getDate() - today.getDay() + 1;
      const monday = new Date(today.setDate(first));

      return this.moment(monday).format('YYYY-MM-DD');
    },
    getSundayOfCurrentWeek() {
      const today = new Date();
      const first = today.getDate() - today.getDay() + 7;
      const sunday = new Date(today.setDate(first));

      return this.moment(sunday).format('YYYY-MM-DD');
    },
    handleRangeDateClick() {
      this.$emit('sendDate');
    },
    clearDate() {
      this.value1 = '';
      this.value2 = '';
      this.$emit('clearDate');
    },
    modifySecondValue() {
      this.value2 = '';
      this.value2week = this.$t('select_date');
      this.disableButtonA = true;
      this.disableButtonC = true;
    },
  },
};
</script>
<style scoped lang="scss">
label {
  color: #a1a8c0;
}

.form-control {
  background: transparent;
  color: #565f77;
  height: 40px;

  &::placeholder {
    color: #565f77;
    font-size: 13px;
  }
}

.calendar-icon {
  position: relative;
}

.disabled {
  color: #a1a8c0;
}
</style>
