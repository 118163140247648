<template>
  <div :class="!isRelapseView">
    <b-row
      class="align-items-center justify-content-center"
      :class="!isRelapseView"
    >
      <b-col>
        <vc-donut
          background="white"
          foreground="grey"
          :size="isRelapseView ? 140 : 160"
          unit="px"
          :thickness="30"
          legend-placement="bottom"
          :sections="sections"
          :total="statistics.total"
          :start-angle="0"
          :auto-adjust-text-size="true"
          class="report-chart"
        >
          <div class="chart-text" :class="isRelapseView && 'chart-text-sm'">
            <span class="d-block">{{
              statistics.completed + statistics.notCompleted
            }}</span>
            <span> {{ $t('total') }}</span>
          </div>
        </vc-donut>
      </b-col>
      <b-col>
        <div
          v-for="(item, index) in statisticsSection"
          :key="index"
          class="report-numbers d-flex align-items-center"
        >
          <div class="circle" :style="{ backgroundColor: item.color }"></div>
          <div class="d-flex flex-column pl-3 pb-3">
            <span class="avenir chart-text">
              {{ item.value }}
            </span>
            <span class="opacity-6 text-sm">{{ getTextKey(item.label) }}</span>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'StatisticsReport',
  props: {
    statistics: Object,
    statisticsSection: Array,
  },
  data() {
    return {
      isRelapseView: this.$route.name.includes('view-relapse'),
      sections: [],
      statisticsTotal: undefined,
    };
  },
  created() {
    // workaround to allow vc-donut work with no items
    if (this.statistics.total === 0) this.statistics.total = 1;
    this.getSections(this.statisticsSection);
  },
  methods: {
    getSections(value) {
      this.sections = value;
    },
    getTextKey(label) {
      const labels = {
        notCompleted: 'not_completed',
        completed: 'completed',
      };
      return this.$t(labels[label]);
    },
  },
};
</script>
