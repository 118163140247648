<template>
  <div>
    <b-row class="border-bottom mx-0">
      <p class="avenir-bold text-sm">
        {{
          transformParams($t('relapse_schedule_duration_text'), (duration = 6))
        }}
      </p>
      <p class="ml-auto text-sm" v-if="schedules.scheduleStartedAt">
        <span class="opacity-6">{{ $t('date_start') }}</span>
        <span class="avenir-bold mx-2">
          {{ moment(schedules.scheduleStartedAt).format('DD/MM/YYYY') }}
        </span>
        <span class="opacity-6">{{ $t('date_end') }}</span>
        <span class="avenir-bold mx-2">
          {{ moment(schedules.scheduleEndAt).format('DD/MM/YYYY') }}
        </span>
      </p>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="5" class=""></b-col>
      <b-col cols="7" class="">
        <b-row class="mb-4 text-center">
          <b-col class="text-sm" v-for="(day, i) in daysTextKeys" :key="i">
            {{ $t(day) }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row
      class="border-bottom py-4"
      v-for="schedule in orderedTasksList"
      :key="schedule.id"
    >
      <b-col span="5" class="">
        <b-row
          :class="[
            relapseTableColorTextClass(schedule.hasPendingChanges),
            relapseTableDecorationTextClass(
              schedule.hasPendingChanges,
              schedule.isEnabled
            ),
          ]"
        >
          <b-col class="avenir-bold text-sm">{{ $t(schedule.taskName) }}</b-col>
          <b-col class="avenir-bold text-sm">
            {{ transformParams($t('relapse_schedule_cycle'), (duration = 1)) }}
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="7" class="">
        <b-row class="list-inline text-center">
          <template>
            <b-col v-for="(day, index) in daysTextKeys" :key="index">
              <i
                v-if="day === 'day_tue' || day === 'day_fri'"
                class="ni ni-check-bold"
                :class="[
                  schedule.hasPendingChanges ? 'purple-text' : 'text-primary',
                ]"
              ></i>
            </b-col>
          </template>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { transformDurationParams } from '../../utils/stringParams';

export default {
  props: {
    schedules: Object,
  },

  data() {
    return {
      daysTextKeys: [
        'day_mon',
        'day_tue',
        'day_wed',
        'day_thu',
        'day_fri',
        'day_sat',
        'day_sun',
      ],
    };
  },

  computed: {
    filteredTestSchedules() {
      return this.schedules.relapseTestSchedules.filter(
        (test) => test.status.name !== 'DELETE'
      );
    },
    orderedTasksList() {
      const tasksList = this.filteredTestSchedules;
      return tasksList
        .sort((a, b) =>
          a.testVersion.test.name.localeCompare(b.testVersion.test.name)
        )
        .reduce((acc, item, index) => {
          acc[index] = {
            taskName: item.testVersion.test.name,
            hasPendingChanges: item.hasPendingChanges,
            isEnabled: item.isEnabled,
          };
          return acc;
        }, {});
    },
  },
  methods: {
    transformParams(string, num) {
      return transformDurationParams(string, num);
    },
    relapseTableColorTextClass(hasPendingChanges) {
      return hasPendingChanges ? 'purple-text' : '';
    },
    relapseTableDecorationTextClass(hasPendingChanges, isEnabled) {
      const willBeDisabledNextWeek = hasPendingChanges && isEnabled;
      return willBeDisabledNextWeek ? 'line-through-Text' : '';
    },
  },
};
</script>

<style scoped>
.purple-text {
  color: #3331e0;
}
.line-through-Text {
  text-decoration: line-through;
}
</style>
