export default {
  data() {
    return {
      range: {
        start: null,
        end: null,
      },
    };
  },

  methods: {
    getCorrectDateFormat(date) {
      return this.moment(date).format('YYYY-MM-DD');
    },
    getEndRangeDateFormat(date) {
      return this.moment(date).format('YYYY-MM-DD');
    },
    getSundayOfCurrentWeek() {
      const today = new Date();
      const first = today.getDate() - today.getDay() + 7;

      const sunday = new Date(today.setDate(first));
      return this.moment(sunday).format('YYYY-MM-DD');
    },
    getMondayOfCurrentWeek() {
      const today = new Date();
      const first = today.getDate() - today.getDay() + 1;

      const monday = new Date(today.setDate(first));
      return this.moment(monday).format('YYYY-MM-DD');
    },
    clearDate() {
      this.range = {
        start: null,
        end: null,
      };
      this.getStatistics(
        this.getMondayOfCurrentWeek(),
        this.getSundayOfCurrentWeek()
      );
    },

    handleRangeDateClick() {
      const start = this.getCorrectDateFormat(this.range.start);
      const rangeEnd = this.getEndRangeDateFormat(this.range.end);
      if (this.range.end === null) {
        let startDatePlus6 = this.moment(this.range.start, 'YYYY-MM-DD').add(
          6,
          'days'
        );
        let endRange = this.getEndRangeDateFormat(startDatePlus6);
        this.getStatistics(start, endRange);
      } else {
        this.getStatistics(start, rangeEnd);
      }
    },
  },
};
